import React from "react";

function Notification({ content, isEnable }) {
  return (
    <>
      {isEnable && (
        <div className="notification-bar text-center">
          <p>
            <i className="pi pi-info-circle"></i>
            <span dangerouslySetInnerHTML={{ __html: content }} />
          </p>
        </div>
      )}
    </>
  );
}

export default Notification;
