import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";
import "./style.css";
import ReadMore from "../ReadMore/ReadMore";

function EligibilityRequirements({ pageData, lang }) {
    let history = useHistory();

    function redirectToAssessment() {
        history.push("/eligibility-assessment");
    }

    const backToTop = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    return (
        <main id="main">
            <div className="eligibility-main-content">
                <div className="container-fluid">
                    <div className="row g-0">
                        <div className="eligibility-header col-md-12 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
                            <div className="overlay"></div>
                            <div className="banner-text-div mx-auto"><h1 className="banner-text">{pageData.banner.title}</h1></div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="eligibility-requirements row  g-0">
                        <div className="eligibility-steps col-md-8 col-sm-12 mx-auto">
                            <h2>{pageData.requirements.title}</h2>
                            <ul className="custom-list">
                                {pageData.requirements.list.map((item, index) => {
                                    if (index === 3) {
                                        return lang === 'english' ?
                                            <li key={index}>You do not have insurance coverage for a <Link to='/medicines'><span class="bold-span">medicine listed on this site</span></Link>, and</li>
                                            :
                                            <li key={index}>No tiene cobertura de seguro para un <Link to='/medicines'><span class="bold-span">medicamento enumerado en este sitio</span></Link></li>
                                    } else {
                                        return <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                                    }
                                })}
                            </ul>
                            <p className="body-bold" dangerouslySetInnerHTML={{ __html: pageData.requirements.body }}></p>
                            <p className="body" dangerouslySetInnerHTML={{ __html: pageData.requirements.footnote }}></p>
                        </div>
                    </div>
                    <div className="row  g-0">
                        <div className="find-eligibility col-md-8 col-sm-12 mx-auto">
                            <div className="pink-bar"></div>
                            <div className="eligibility-content">
                                <h2>{pageData.assessment.title}</h2>
                                <p>{pageData.assessment.body}</p>
                                <Button
                                    label={pageData.assessment.btn.label}
                                    className="p-button-outlined p-button-help"
                                    onClick={redirectToAssessment}
                                />
                            </div>
                        </div>
                    </div>
                    <br /><br />
                    <div className="row g-0">
                        <div className="col-md-8 col-sm-12 mx-auto">
                            <Link tabIndex={0} style={{ marginTop: "1em", color: "black", borderBottom: "2px solid #EB7100", textDecoration: "none", whiteSpace: "nowrap" }} onClick={(e) => backToTop(e)}><span class="bold-span">{pageData.backBtnLabel}</span> </Link>
                        </div>
                    </div>
                </div>
                <ReadMore content={pageData.readMore} />
            </div>
        </main>
    );

}

export default EligibilityRequirements;