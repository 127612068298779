import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

function LegalNotice({ pageData }) {
  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return (
    <main id="main" className="legal-notice-main-div">
      <div className="legal-content container">
        <div className="row g-0">
          <div className="privacy-content-body col-md-8 col-sm-8 col-lg-8 col-xl-8 col-sm-12 mx-auto">
            <div>
              <h1>{pageData.legalContent.heading}</h1>
              <h2>{pageData.legalContent.title}</h2>
              <p>{pageData.legalContent.description}</p>
            </div>
            <div>
              <h2>{pageData.scopeContent.title}</h2>
              <p>{pageData.scopeContent.description}</p>
            </div>
            <div>
              <h2>{pageData.medicalAdvice.title}</h2>
              <p>{pageData.medicalAdvice.description}</p>
            </div>
            <div>
              <h2>{pageData.productInfo.title}</h2>
              <p>{pageData.productInfo.description}</p>
            </div>
            <div>
              <h2>{pageData.copyrightProtect.title}</h2>
              <p>{pageData.copyrightProtect.description}</p>
            </div>
            <div>
              <h2>{pageData.trade.title}</h2>
              <p>{pageData.trade.description}</p>
            </div>
            <div>
              <h2>{pageData.warranty.title}</h2>
              <p>{pageData.warranty.description}</p>
            </div>
            <div>
              <h2>{pageData.transmission.title}</h2>
              <p>{pageData.transmission.description}</p>
            </div>
            <div>
              <h2>{pageData.userSubmission.title}</h2>
              <p>{pageData.userSubmission.description}</p>
            </div>
            <div>
              <h2>{pageData.lnktoSites.title}</h2>
              <p>{pageData.lnktoSites.description}</p>
            </div>
            <div>
              <h2>{pageData.govLaw.title}</h2>
              <p>{pageData.govLaw.description}</p>
            </div>
            <div>
              <h2>{pageData.agreement.title}</h2>
              <p>{pageData.agreement.description}</p>
            </div>
          </div>
        </div>
        <div className="back-to-top row g-0" style={{ marginBottom: "2rem" }}>
          <div className="col-md-8 col-sm-12 mx-auto">
            <Link
              tabIndex={0}
              style={{
                marginTop: "1em",
                color: "black",
                "border-bottom": "2px solid #EB7100",
                textDecoration: "none",
              }}
              onClick={(e) => backToTop(e)}
            >
              <span class="bold-span">{pageData.backBtnLabel}</span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default LegalNotice;
