import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { medicine_english, medicine_spanish } from "../../Assets/Medicine.json";
import "./style.css";
import ReadMore from "../ReadMore/ReadMore";
import { Link } from "react-router-dom";
import ContactUs from "../EligibilityRequirements/ContactUs";
import DownloadForm from "./DownloadForm";

function HowToApply({ pageData, lang }) {
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    lang === 'english' ?
      setMedicines(medicine_english.map((m) => ({
        label: m.tradeNameStr + " (" + m.drugName + ") " + m.tradeNameSuffix,
        medicineDetails: m,
      })))
      :
      setMedicines(medicine_spanish.map((m) => ({
        label: m.tradeNameStr + " (" + m.drugName + ") " + m.tradeNameSuffix,
        medicineDetails: m,
      })))
  }, [lang])

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  const openDocument = () => {
    setShowDialog(true);
  };

  const updateShowDialog = (isShow) => {
    setShowDialog(isShow);
  };

  return (
    <main id="main">
      <div className="how-to-apply">
        <div className="container-fluid">
          <div className="row g-0">
            <div className="howto-header col-md-12 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
              <div className="overlay"></div>
              <div className="banner-text-div mx-auto">
                <h1 className="banner-text">{pageData.banner.title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="apply container">
          <div className="apply-title row g-0">
            <div className="apply-pointer-one col-md-8 col-sm-12 mx-auto">
              {lang === 'english' ?
                <p className="title-string">
                  If your doctor has prescribed one of the <span class="underline body-bold"> <Link to="/medicines">medicines listed here</Link> </span> and you meet the <span class="underline body-bold"><Link to="/eligibility">eligibility requirements</Link></span>, follow these steps to apply:
                </p>
                :
                <p className="title-string">
                  Si su médico le ha recetado uno de los <span class="underline body-bold"> <Link to="/medicines">medicamentos enumerados aquí</Link></span> y usted cumple con los <span class="underline body-bold"><Link to="/eligibility">requisitos de elegibilidad</Link></span>, sigue estos pasos para aplicar:
                </p>
              }
              <div><h2><span className="circle-outline">{pageData.body.index_1.id}</span>&nbsp;{pageData.body.index_1.title}</h2></div>
              <div className="custom-list-div">
                <ul className="custom-list">
                  {pageData.body.index_1.list.map(item => (
                    <li dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              </div>
              <div className="field-container">
                <p className="body-bold">{pageData.dropdown.title}</p>
                <Dropdown
                  readonly={false}
                  aria-label="medicine"
                  placeholder={pageData.dropdown.label}
                  value={selectedMedicine}
                  options={medicines}
                  onChange={(e) => setSelectedMedicine(e.value)}
                />
                <div className="download-application-button">
                  <span>
                    <Button
                      label={pageData.body.index_1.btnLabel}
                      className="p-button-outlined p-button-help"
                      disabled={!selectedMedicine}
                      onClick={() => openDocument()}
                    />
                  </span>
                  <span className="call-fax"><p dangerouslySetInnerHTML={{ __html: pageData.body.index_1.contactMsg }}></p></span>
                </div>
              </div>
              <hr aria-hidden="true" />
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <p><h2><span className="circle-outline">{pageData.body.index_2.id}</span>&nbsp;{pageData.body.index_2.title}</h2></p>
              <p className="doctor-signature">{pageData.body.index_2.text}</p>
              <hr aria-hidden="true" />
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <p><h2><span className="circle-outline">{pageData.body.index_3.id}</span>&nbsp;{pageData.body.index_3.title}</h2></p>
              <div className="send-application-list">
                <ul className="custom-list">
                  {pageData.body.index_3.list.map(item => (
                    <li dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="apply-contact row g-0">
            <ContactUs contactData={pageData.moreInfo.contactContent} />
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <Link
                tabIndex={0}
                style={{
                  marginTop: "1em",
                  color: "black",
                  "border-bottom": "2px solid #EB7100",
                  textDecoration: "none",
                }}
                onClick={(e) => backToTop(e)}
              >
                <span class="bold-span">{pageData.backBtnLabel}</span>
              </Link>
            </div>
          </div>
        </div>
        <ReadMore content={pageData.readMore} />
      </div >
      <DownloadForm
        lang={lang}
        dialogData={pageData.downloadForm}
        selectedMedicine={selectedMedicine}
        showDialog={showDialog}
        updateShowDialog={updateShowDialog}
      />
    </main >
  );
}

export default HowToApply;
