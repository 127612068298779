import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReadMore from "../ReadMore/ReadMore";
import "./style.css";
import { medicine_english, medicine_spanish } from "../../Assets/Medicine.json";

function Medicines({ pageData, lang }) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getClassNames = (index) => {
    if (partition % 2 === 0) {
      return index % 2 !== 0
        ? "medicine-list-item color-me"
        : "medicine-list-item";
    }
    return (index < partition && index % 2 !== 0) ||
      (index >= partition && index % 2 === 0)
      ? "medicine-list-item color-me"
      : "medicine-list-item";
  };
  const [partition, setPartition] = useState(0);
  const [listItems, setListItems] = useState(null);
  const [medicines, setMedicines] = useState(medicine_english);

  useEffect(() => {
    lang === 'english' ?
      setMedicines(medicine_english)
      :
      setMedicines(medicine_spanish)
  }, [lang])
  useEffect(() => {
    if (partition === 0) {
      let len =
        medicines.length % 2 === 0
          ? medicines.length / 2
          : (medicines.length + 1) / 2;
      // console.log('len', len)
      if (len % 2 === 0) {
        setPartition(len)
      } else {
        setPartition(len - 1);
      }
    }
  }, [medicines]);

  useEffect(() => {
    setListItems(
      medicines.map((medicine, index) => {
        // console.log('index1', index)
        let itemLiElem = (
          <li className={getClassNames(index)} key={medicine.tradeNameStr}>
            <p className="trade-name">{medicine.tradeNameStr}</p>
            <p className="drug-name">
              {"(" + medicine.drugName + ")" + medicine.tradeNameSuffix}
              <sup>
                <Link onClick={(e) => scrollToStar(e)}>{medicine.star}</Link>
              </sup>
            </p>
          </li>
        );
        if (
          index === medicines.length - 1 &&
          medicines.length % 2 !== 0
        ) {
          return (
            <>
              {itemLiElem}
              <li className={getClassNames(index)} key={medicine.tradeNameStr}>
                <p className="trade-name"></p>
                <p className="drug-name"></p>
              </li>
            </>
          );
        } else {
          return itemLiElem;
        }
      })
    );
  }, [partition]);

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  const scrollToStar = (e) => {
    e.preventDefault();
    const bot = document.body.scrollHeight - 800;
    window.scrollTo(0, bot);
  }

  return (
    <main id="main">
      <div className="medicines-main-content">
        <div className="container-fluid">
          <div className="row g-0">
            <div className="medicines-header col-sm-12 mx-auto d-flex justify-content-center align-items-center">
              <div className="overlay"></div>
              <div className="banner-text-div mx-auto">
                <h1 className="banner-text">{pageData.banner.title}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="medicines-content container">
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <h2 className="medicine-title">{pageData.medicineTitle.title}</h2>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <ul className="medicines-list">{listItems}</ul>
              <div style={{ clear: "both" }}></div>
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              {pageData.medicineDescription.body.map((item, index) => {
                return <p className="restricted" key={index} dangerouslySetInnerHTML={{ __html: item }}></p>;
              })}
            </div>
          </div>
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <Link
                tabIndex={0}
                style={{
                  marginTop: "1em",
                  color: "black",
                  "border-bottom": "2px solid #EB7100",
                  textDecoration: "none",
                }}
                onClick={(e) => backToTop(e)}
              >
                <span class="bold-span">{pageData.backBtnLabel}</span>{" "}
              </Link>
            </div>
          </div>
        </div>
        <ReadMore content={pageData.readMore} />
      </div>
    </main>
  );
}

export default Medicines;
