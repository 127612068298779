import { useEffect, useState } from 'react';
function Burger({ menuOpen, setMenuOpen }) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        setOpen(!menuOpen);
    });
    const transformBurger = (e) => {
        let burgerDiv = document.getElementById('burger-div');
        let elements = burgerDiv.getElementsByTagName('div');
        // if(!open) {
        //     elements[0].classList.add('transform-first');
        //     elements[1].classList.add('transform-second');
        //     elements[2].classList.add('transform-third');
        // } else {
        //     elements[0].classList.remove('transform-first');
        //     elements[1].classList.remove('transform-second');
        //     elements[2].classList.remove('transform-third');
        // }
        setOpen(!open);
        setMenuOpen(!menuOpen);
    };
    return (
        <div id="burger-div" className="styled-burger ms-auto" onClick={(e) => transformBurger(e)}>
            <div className={!open ? "transform-first" : ""}></div>
            <div className={!open ? "transform-second" : ""}></div>
            <div className={!open ? "transform-third" : ""}></div>
        </div>
    );
}

export default Burger;