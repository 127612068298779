import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import BMSPAF_Video_final from "../../Video/BMSPAF_Video_final.mp4";
// import data from "../../Assets/EnglishData.json";
// import Data from "../../pafData.json"

function AboutUs({ setCurrentTab, pageData, lang }) {
  useEffect(() => {
    if (setCurrentTab) {
      setCurrentTab(1);
    }
  });
  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return (
    <main id="main">
      <div className="aboutus">
        <div className="container-fluid">
          <div className="row g-0">
            <div className="aboutus-header col-sm-12 mx-auto d-flex justify-content-center align-items-center">
              <div className="overlay"></div>
              <div className="banner-text-div mx-auto">
                <h1 className="banner-text">{pageData.banner.title}</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row g-0">
            <div className="aboutus-content col-md-8 col-sm-8 col-lg-8 col-xl-8 col-sm-12 mx-auto">
              <p className="body-large">{pageData.aboutUsContent.title}</p>
              {/* {lang === 'english' && <video tabIndex={0} src={pageData.video && require(`../../Video/${pageData.video}`).default} controls={true}></video>} */}
              {/* <track
                  label="English"
                  kind="subtitles"
                  srclang="en"
                  src={require('../../Assets/track.vtt')}
                /> */}
              {/* <video src={BMSPAF_Video_final} controls="true"></video> */}
              <h2>{pageData.pafCustList.title}</h2>
              <ul className="custom-list">
                {pageData.pafCustList.body.map((item, index) => {
                  if (index === 0) {
                    return lang === 'english' ?
                      <li>{item} <span class="bold-span"><Link to='/eligibility'>See if you may be eligible.</Link></span></li>
                      :
                      <li>{item} <span class="bold-span"><Link to='/eligibility'>Averigüe si puede ser elegible.</Link></span></li>
                  } else {
                    return <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                  }
                })}
              </ul>
            </div>
          </div>
          <div className="row g-0">
            <div className="important-note  col-md-8 col-sm-8 col-lg-8 col-xl-8 col-sm-12 mx-auto">
              <div className="pink-bar"></div>
              <div className="main-content">
                <h3>{pageData.impNote.heading}</h3>
                <p className="body-bold">{pageData.impNote.title}</p>
                <ul className="custom-list">
                  {pageData.impNote.body.map((item, index) => {
                    return <li key={index}>{item}</li>;
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="bms-assistance col-md-8 col-sm-8 col-lg-8 col-xl-8 col-sm-12 mx-auto">
              <div className="asst-body">
                {pageData.contact.title}
                <br />
                <span className="contact-wrap">
                  <a href={`tel:+${pageData.contact.number}`}>
                    <span class="bold-span">{pageData.contact.number}</span>
                  </a>
                  .
                </span>
                <br />
                <br />
                <span class="bold-span">{pageData.contact.heading}</span>
                <br />
                {pageData.contact.body.map((item, index) => {
                  return <div key={index}>{item}<br /></div>;
                })}
                <br />
                <Link
                  tabIndex={0}
                  style={{
                    color: "black",
                    borderBottom: "2px solid #EB7100",
                    textDecoration: "none",
                  }}
                  onClick={(e) => backToTop(e)}
                >
                  {pageData.backBtnLabel}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default AboutUs;
