import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import close from "../../Images/close.svg";
import ReadMore from "../ReadMore/ReadMore";

function NotEligible({ pageData }) {

    const backToTop = (e) => {
        e.preventDefault();
        window.scrollTo(0, 0);
    }

    return (
        <main id="main">
            <div className="not-eligible-main">
                <div className="not-eligible-content container">
                    <div className="row g-0">
                        <div className="col-sm-12 mx-auto">
                            <h1>{pageData.title}</h1>
                        </div></div></div>
                <div className="container-fluid">
                    <div className="row g-0">
                        <div>
                            <div className="not-eligible-dialogue col-sm-12 mx-auto d-flex justify-content-center align-items-center">
                                <span><img className="close-icon" src={close} alt="close" /></span>
                                <span><h3>{pageData.body}</h3></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="non-eligible-body container">
                    <div className="row g-0">
                        <div className="col-md-8 col-sm-12 mx-auto">
                            <p className="more-info">{pageData.moreInfo.title} <Link to='/eligibility'>{pageData.btnLabel}</Link>.</p>
                            <div className="contact-us">
                                <div className="contact-content">
                                    {pageData.moreInfo.contactContent.map(item => (
                                        <p dangerouslySetInnerHTML={{ __html: item }}></p>
                                    ))}
                                </div>
                            </div>
                        </div></div>
                    <br /><br />
                    <div className="row g-0">
                        <div className="col-md-8 col-sm-12 mx-auto">
                            <Link tabIndex={0} style={{ marginTop: "1em", color: "black", borderBottom: "2px solid #EB7100", textDecoration: "none" }} onClick={(e) => backToTop(e)}><span class="bold-span">{pageData.backBtnLabel}</span> </Link>
                        </div>
                    </div>
                </div>
                <ReadMore content={pageData.readMore} />
            </div>
        </main>
    );
}

export default NotEligible;
