import { Link } from "react-router-dom";

function Menu({ activeIndex, menu }) {

  const menuItems = menu.map((item, index) => {
    return <li key={index} className={activeIndex - 1 === index ? "nav-item active" : "nav-item"}><Link tabIndex={0} aria-current={activeIndex - 1 === index} to={item.link}>{item.label}</Link></li>
  });

  return (
    <ul className="navbar-nav mr-auto">
      {menuItems}
    </ul>
  );
}

export default Menu;