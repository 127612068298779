import React, { useState } from 'react';
import './style.css';
import ReadMore from '../ReadMore/ReadMore';
import { Link } from 'react-router-dom';
import Faq from "react-faq-component";
import { Checkbox } from "primereact/checkbox";
import primaryApplication from '../../Documents/English/Primary_Application.pdf';
import OncologyHematologyRenewalApplication from '../../Documents/English/Oncology_Hematology_Renewal_Krazati.pdf';
import CobenfyApplication from '../../Documents/English/Cobenfy_Application.pdf';
import zeposiaApplication from '../../Documents/English/Zeposia_Application.pdf';
import RemsRenewalApplication from '../../Documents/English/REMS_Renewal_Application.pdf';
import CamzyosRenewalApplication from '../../Documents/English/Camzyos_Application.pdf';
import ContactUs from '../EligibilityRequirements/ContactUs';

import OncologyHematologyRenewalApplication_Spanish from '../../Documents/Spanish/Oncology_Hematology_Renewal_Krazati.pdf';
import CobenfyApplication_Spanish from '../../Documents/Spanish/Cobenfy_Application.pdf';
import primaryApplication_Spanish from '../../Documents/Spanish/Primary_Application.pdf';
import zeposiaApplication_Spanish from '../../Documents/Spanish/Zeposia_Application.pdf';
import RemsRenewalApplication_Spanish from '../../Documents/Spanish/REMS_Renewal_Application.pdf';
import CamzyosRenewalApplication_Spanish from '../../Documents/Spanish/Camzyos_Application.pdf';

function Renew({ pageData, lang }) {
   const [checked, setChecked] = useState(false);
   const sectionOne = {
     //title: pageData.questionSections.section1.title,
     rows: [
       {
         title: pageData.questionSections.section1.rows[0].title,
         content: pageData.questionSections.section1.rows[0].content,
       },
       {
         title: pageData.questionSections.section1.rows[1].title,
         content: pageData.questionSections.section1.rows[1].content,
       },
       {
         title: pageData.questionSections.section1.rows[2].title,
         content: (
           <div>
             <ul className="custom-list">
               {pageData.questionSections.section1.rows[2].content.map(
                 (item) => (
                   <li>{item}</li>
                 )
               )}
             </ul>
           </div>
         ),
       },
       {
         title: pageData.questionSections.section1.rows[3].title,
         content: (
           <div>
             <ul className="custom-list">
               {pageData.questionSections.section1.rows[3].content.map(
                 (item) => (
                   <li>{item}</li>
                 )
               )}
             </ul>
           </div>
         ),
       },
       {
         title: pageData.questionSections.section1.rows[4].title,
         content: pageData.questionSections.section1.rows[4].content,
       },
       {
         title: pageData.questionSections.section1.rows[5].title,
         content: (
           <div>
             <ul className="custom-list">
               {pageData.questionSections.section1.rows[5].content.map(
                 (item) => (
                   <li>{item}</li>
                 )
               )}
             </ul>
           </div>
         ),
       },
       {
         title: pageData.questionSections.section1.rows[6].title,
         content: pageData.questionSections.section1.rows[6].content,
       },
       {
         title: pageData.questionSections.section1.rows[7].title,
         content: pageData.questionSections.section1.rows[7].content,
       },
     ],
     styles: {
       titleTextColor: "#3E000C",
       titleTextSize: "25px",
     },
   };
   const config = {
      arrowIcon: " ",
      tabFocus: true
    };
    const toggleExpansion = (e) => {
      let rows = document.getElementsByClassName("faq-row");
      if (e.checked) {
        expandAll(rows);
      } else {
        collapseAll(rows);
      }
      setChecked(e.checked);
    };

    const collapseAll = (rows) => {
      for (let i = 0; i < rows.length; i++) {
        let rowTitle = rows[i].getElementsByClassName("row-title")[0];
        rowTitle.classList.remove("expanded", "styles_expanded__3elPy");
        rowTitle.classList.add("closed", "styles_closed__39w54");
        rowTitle.setAttribute("aria-expanded", "false");
        let rowContent = rows[i].getElementsByClassName("row-content")[0];
        rowContent.setAttribute("aria-expanded", "false");
        rowContent.setAttribute("aria-hidden", "true");
        rowContent.setAttribute("style", "height: 0px;");
      }
    };
  
    const expandAll = (rows) => {
      for (let i = 0; i < rows.length; i++) {
        let rowTitle = rows[i].getElementsByClassName("row-title")[0];
        rowTitle.classList.add("expanded", "styles_expanded__3elPy");
        rowTitle.classList.remove("closed", "styles_closed__39w54");
        rowTitle.setAttribute("aria-expanded", "true");
        let rowContent = rows[i].getElementsByClassName("row-content")[0];
        rowContent.setAttribute("aria-expanded", "true");
        rowContent.setAttribute("aria-hidden", "false");
        rowContent.setAttribute(
          "style",
          "height: " + rowContent.scrollHeight + "px;"
        );
      }
    };
   const backToTop = (e) => {
      e.preventDefault();
      window.scrollTo(0, 0);
   };

   const openRenewDocument = (documentTitle) => {
      let url = ' ';
      if (documentTitle === 'OncologyHematologyDocument') {
         url =
            lang === 'english'
               ? window.open(OncologyHematologyRenewalApplication)
               : window.open(OncologyHematologyRenewalApplication_Spanish);
      } else if (documentTitle === 'RemsDocument') {
         url =
            lang === 'english'
               ? window.open(RemsRenewalApplication, '_blank')
               : window.open(RemsRenewalApplication_Spanish, '_blank');
      } else if (documentTitle === 'PrimaryDocument') {
         url =
            lang === 'english'
               ? window.open(primaryApplication, '_blank')
               : window.open(primaryApplication_Spanish, '_blank');
      } else if (documentTitle === 'ZeposiaDocument') {
         url =
            lang === 'english'
               ? window.open(zeposiaApplication, '_blank')
               : window.open(zeposiaApplication_Spanish, '_blank');
      } else if (documentTitle === 'CobenfyDocument') {
         url =
            lang === 'english'
               ? window.open(CobenfyApplication, '_blank')
               : window.open(CobenfyApplication_Spanish, '_blank');
      } else {
         url =
            lang === 'english'
               ? window.open(CamzyosRenewalApplication, '_blank')
               : window.open(CamzyosRenewalApplication_Spanish, '_blank');
      }

      // documentTitle === 'OncologyHematologyDocument' &&
      //    window.open(OncologyHematologyRenewalApplication, '_blank');
      // documentTitle === 'RemsDocument' &&
      //    window.open(RemsRenewalApplication, '_blank');
      // documentTitle === 'PrimaryDocument' &&
      //    window.open(primaryApplication, '_blank');
      // documentTitle === 'ZeposiaDocument' &&
      //    window.open(zeposiaApplication, '_blank');
      // documentTitle === 'CamzyosDocument' &&
      //    window.open(CamzyosRenewalApplication, '_blank');
   };

   const openHemOncDocument = () => { };

   const openRemsRenewelDocument = () => { };

   const openPrimeRenewalDocument = () => { };

   const openZeposiaAppDocument = () => { };

   const downloadDocument = () => {
      openRenewDocument();
   };
   return (
      <main id="main">
         <div className="how-to-apply">
            <div className="container-fluid">
               <div className="row g-0">
                  <div className="howto-header col-md-12 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
                     <div className="overlay"></div>
                     <div className="banner-text-div mx-auto">
                        <h1 className="banner-text">{pageData.banner.title}</h1>
                     </div>
                  </div>
               </div>
            </div>
            <div className="container mt-5">
               <div className="apply-title row  g-0">
                  <div className="apply-pointer-one col-md-8 col-sm-12 mx-auto">
                     <p className="title-string">{pageData.renewContent}</p>
                     <p className="title-string mt-5">
                        <span class="bold-span">
                           <u>{pageData.questionSections.inflationReductionTitle}</u>
                        </span>
                     </p>
                     <p className="title-string">{pageData.questionSections.inflationReductionDesc}</p>
                     <div className="checkbox1 col-sm-12 col-md-12 mx-auto mt-5">
                     <label className="faq-all">{pageData.questionSections.section1.title}</label>
                     <div className="p-field-checkbox">
                        <Checkbox
                           role="checkbox"
                           aria-label="Expand all questions"
                           aria-checked={checked}
                           className="expand-all-box"
                           inputId="binary"
                           checked={checked}
                           onChange={(e) => toggleExpansion(e)}
                           onKey
                        />
                        <label className="expand-all">{pageData.toggleLabel}</label>
                     </div>
                     
                  </div>
                     <div className="faq-content col-sm-12 col-md-12 mx-auto mt-5">
                     <Faq id="r1" data={sectionOne} config={config} />
                     </div>

                     <p className="title-string mt-5">
                        <span class="bold-span">
                           <u>{pageData.assistanceList.title}</u>
                        </span>
                     </p>
                     <ul className="custom-list">
                        {pageData.assistanceList.list.map((item) => (
                           <li dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                     </ul>
                     <br />
                     <br />
                     <br />
                     <div class="container">
                        <table class="table table-bordered">
                           <thead>
                              <tr>
                                 {pageData.table1.headers.map((item) => (
                                    <th>
                                       <span class="bold-span">{item}</span>
                                    </th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {pageData.table1.rows.map((cell, index) => (
                                 <tr>
                                    <td>
                                       <ul>
                                          {cell[0].map((item) => (
                                             <li>{item}</li>
                                          ))}
                                       </ul>
                                    </td>
                                    <td>
                                       <span className="underline body-bold">
                                          <Link
                                             to="#"
                                             onClick={() =>
                                                openRenewDocument(cell[1])
                                             }>
                                             {cell[2]}
                                          </Link>
                                       </span>
                                       {/* {(index === 0 || index === 1) ?
                                          <span
                                             className="table-msg"
                                             dangerouslySetInnerHTML={{
                                                __html: cell[2],
                                             }}>
                                          </span>
                                          :
                                          <span className="underline body-bold">
                                             <Link
                                                to="#"
                                                onClick={() =>
                                                   openRenewDocument(cell[1])
                                                }>
                                                {cell[2]}
                                             </Link>
                                          </span>
                                       } */}
                                    </td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                     <hr aria-hidden="true" />
                     <br />
                     <br />
                     <br />
                     <p className="title-string">
                        <span class="bold-span">
                           <u>{pageData.table2.title}</u>
                        </span>
                     </p>
                     <div class="container">
                        <table class="table table-bordered">
                           <thead>
                              <tr>
                                 {pageData.table2.headers.map((item) => (
                                    <th>
                                       <span class="bold-span">{item}</span>
                                    </th>
                                 ))}
                              </tr>
                           </thead>
                           <tbody>
                              {pageData.table2.rows.map((cell) => (
                                 <tr>
                                    <td>
                                       <span class="bold-span">{cell[0]}</span>
                                    </td>
                                    <td>{cell[1]}</td>
                                 </tr>
                              ))}
                           </tbody>
                        </table>
                     </div>
                     <hr aria-hidden="true" />
                     <br />
                     <br />
                     <br />
                     <p className="title-string">
                        <span class="bold-span">
                           <u>{pageData.impInfoList.title}</u>
                        </span>
                     </p>
                     <ul className="custom-list">
                        {pageData.impInfoList.list.map((item) => (
                           <li
                              className="custom-list-item"
                              dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                     </ul>
                     <br />
                     <p className="title-string">
                        <span class="bold-span">
                           <u>{pageData.renewQuestion.question}</u>
                        </span>
                     </p>
                     <p className="title-string">
                        <span class="bold-span">{pageData.renewQuestion.answerList.header}</span>
                     </p>
                     <ul className="custom-list">
                        {pageData.renewQuestion.answerList.list.map((item) => (
                           <li dangerouslySetInnerHTML={{ __html: item }}></li>
                        ))}
                     </ul>
                     <br />
                     <p>{pageData.renewQuestion.answerList.footer}</p>
                     <br />
                  </div>
               </div>
               <div className="apply-contact row g-0">
                  <ContactUs contactData={pageData.moreInfo.contactContent} />
               </div>
               <div className="row g-0">
                  <div className="col-md-8 col-sm-12 mx-auto">
                     <Link
                        tabIndex={0}
                        style={{
                           marginTop: '1em',
                           color: 'black',
                           borderBottom: '2px solid #EB7100',
                           textDecoration: 'none',
                        }}
                        onClick={(e) => backToTop(e)}>
                        <span class="bold-span">{pageData.backBtnLabel}</span>
                     </Link>
                  </div>
               </div>
            </div>
            <ReadMore content={pageData.readMore} />
         </div>
      </main>
   );
}

export default Renew;
