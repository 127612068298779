import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import "./style.css";
import ReadMore from "../ReadMore/ReadMore";

function EligibilityAssessment({ pageData }) {
  let history = useHistory();
  function startAssessment() {
    history.push("/eligibility-assessment/questions");
  }
  return (
    <main id="main">
      <div className="assessment-main-div">
        <div className="eligibility-assessment container">
          <div className="row g-0">
            <div className="assessment col-sm-12 mx-auto">
              <h1>{pageData.title}</h1>
            </div>
          </div>
        </div>
        <hr aria-hidden="true" />
        <div className="assessment-body container">
          <div className="row g-0">
            <div className="assessment-body col-md-8 col-sm-8 col-sm-12 mx-auto">
              <p className="assessment-content">{pageData.body}</p>
              <div className="btn-container">
                <Button
                  label={pageData.btn.label}
                  className="p-button-outlined p-button-help"
                  onClick={startAssessment}
                />
              </div>
            </div>
          </div>
        </div>
        <ReadMore content={pageData.readMore} />
      </div>
    </main>
  );
}

export default EligibilityAssessment;