function WhatsNext({ content }) {
  return (
    <div className="whats-next container">
      <h2 className="row g-0 justify-content-center">{content.title}</h2>
      <ul className="row g-0">
        {content.list.map(item => (
          <li className={`${item.class} col-12 col-md-4`}>
            <span><img src={item.image && require(`../../Images/${item.image}`).default} alt="review" /></span>
            <h4 className={`${item.title_class}`}>{item.title}</h4>
            <p>{item.body}</p>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default WhatsNext;