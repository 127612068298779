function ContactUs({ contactData }) {
  return (
    <div className="contact-us col-md-8 col-sm-12 mx-auto">
      <div className="contact-content">
        {contactData.map(item => (
          <p dangerouslySetInnerHTML={{ __html: item }}></p>
        ))}
      </div>
      {/* <div className="icon-container"></div> */}
    </div>
  );
}

export default ContactUs;