import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './Components/HomePage/HomePage';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import AboutUs from './Components/AboutUs/AboutUs';
import EligibilityAssessment from './Components/EligibilityAssessment/EligibilityAssessment';
import QuestionOne from './Components/EligibilityAssessment/QuestionOne';
import EligibilityRequirements from './Components/EligibilityRequirements/EligibilityRequirements';
import NotEligible from './Components/EligibilityRequirements/NotEligible';
import Eligible from './Components/EligibilityRequirements/Eligible';
import Medicare from './Components/EligibilityRequirements/Medicare';
import Medicines from './Components/Medicines/Medicines';
import HowToApply from './Components/HowToApply/HowToApply';
import FAQ from './Components/FAQ/FAQ';
import RenewYourAssistance from './Components/RenewYourAssistance/Renew';
import LegalNotice from './Components/LegalNotice/LegalNotice';
import PrivacyPolicy from './Components/PrivacyPolicy/PrivacyPolicy';
import './App.css';
import ScrollToTop from './ScrollToTop';

import { english, spanish } from './pafData.json';

function App() {
   const [lang, setLang] = useState(
      localStorage.lang === undefined ? 'english' : localStorage.lang
   );
   const [content, setContent] = useState({
      header: {
         notification: '',
         routes: [],
         header_menu: [
            {
               label: '',
               link: '',
            },
         ],
         contact: {
            label: '',
            number: '',
         },
      },
      footer: {
         text: '',
         body: [],
         medicineList: [],
         copyright: '',
         version: '',
         legalNotice: {
            label: '',
            link: '',
         },
         privacyPolicy: {
            label: '',
            link: '',
         },
         // yourPrivacyChoices: {
         //    label: '',
         //    link: '',
         // },
      },
      home: {
         banner: {
            title: '',
            btn: {
               label: '',
               link: '',
            },
         },
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
         whatispaf: {
            title: '',
            body: '',
            btn1: {
               label: '',
               link: '',
            },
            btn2: {
               label: '',
               link: '',
            },
         },
         pafoptions: [
            {
               image: '',
               class: '',
               title: '',
               body: '',
               btn: {
                  label: '',
                  link: '',
               },
            },
         ],
         homeDialog: {
            interrupter: {
               title: '',
               content: '',
            },
            title: '',
            content: '',
            list1: [],
            list2: [],
            note: '',
            closure: '',
         },
         backBtnLabel: '',
      },
      about: {
         banner: {
            title: '',
            image: '',
         },
         aboutUsContent: {
            title: '',
         },
         video: '',
         pafCustList: {
            title: '',
            body: [],
         },
         impNote: {
            heading: '',
            title: '',
            body: [],
         },
         contact: {
            title: '',
            heading: '',
            body: [],
            number: '',
         },
         backBtnLabel: '',
      },
      eligibility: {
         banner: {
            title: '',
         },
         requirements: {
            title: '',
            list: [],
            body: '',
         },
         assessment: {
            title: '',
            body: '',
            btn: {
               label: '',
            },
         },
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
         backBtnLabel: '',
      },
      elibility_asmt: {
         title: '',
         body: '',
         btn: {
            label: '',
         },
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      eligibility_ques: {
         title: '',
         question_label: '',
         questions: [
            {
               questionText: '',
               hasToolTip: true,
               toolTip: '',
               placeholder: '',
            },
         ],
         previousBtnLabel: '',
         answer_options: {
            true: '',
            false: '',
         },
         navigationBtn: {
            label: '',
            label_final: '',
         },
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      eligibility_success: {
         title: '',
         dialogue: '',
         body: {
            index_1: {
               id: '1',
               title: '',
               list: [],
               btnLabel: '',
               contactMsg: '',
            },
            index_2: {
               id: '2',
               title: '',
               text: '',
            },
            index_3: {
               id: '3',
               title: '',
               list: [],
            },
         },
         moreInfo: {
            contactContent: [],
         },
         whatsNext: {
            title: '',
            list: [
               {
                  image: '',
                  class: '',
                  title_class: '',
                  title: '',
                  body: '',
               },
            ],
         },
         downloadForm: {
            radioBtns: [
               {
                  title: '',
                  label: '',
                  userType: '',
               },
               {
                  title: '',
                  label: '',
                  userType: '',
               },
            ],
            submitLabel: '',
         },
         backBtnLabel: '',
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      eligibility_fail: {
         title: '',
         body: '',
         moreInfo: {
            title: '',
            contactContent: [],
         },
         backBtnLabel: '',
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      eligibility_medicare: {
         title: '',
         dialogue: '',
         body1: {
            title: '',
            header: '',
            list1: [],
            prescriptionExpenses: {
               part1: '',
               part2: '',
            },
            list2: [],
         },
         body2: {
            index_1: {
               id: '1',
               title: '',
               list: [],
               btnLabel: '',
               contactMsg: '',
            },
            index_2: {
               id: '2',
               title: '',
               text: '',
            },
            index_3: {
               id: '3',
               title: '',
               list: [],
            },
         },
         downloadForm: {
            radioBtns: [
               {
                  title: '',
                  label: '',
                  userType: '',
               },
               {
                  title: '',
                  label: '',
                  userType: '',
               },
            ],
            submitLabel: '',
         },
         moreInfo: {
            contactContent: [],
         },
         whatsNext: {
            title: '',
            list: [
               {
                  image: '',
                  class: '',
                  title_class: '',
                  title: '',
                  body: '',
               },
            ],
         },
         backBtnLabel: '',
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      medicines: {
         banner: {
            title: '',
         },
         medicineTitle: {
            title: '',
         },
         medicineDescription: {
            body: [],
         },
         backBtnLabel: '',
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      apply: {
         banner: {
            title: '',
         },
         dropdown: {
            title: '',
            label: '',
         },
         body: {
            index_1: {
               id: '1',
               title: '',
               list: [],
               btnLabel: '',
               contactMsg: '',
            },
            index_2: {
               id: '2',
               title: '',
               text: '',
            },
            index_3: {
               id: '3',
               title: '',
               list: [],
            },
         },
         downloadForm: {
            radioBtns: [
               {
                  title: '',
                  label: '',
                  userType: '',
               },
               {
                  title: '',
                  label: '',
                  userType: '',
               },
            ],
            submitLabel: '',
         },
         backBtnLabel: '',
         moreInfo: {
            contactContent: [],
         },
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      faq: {
         title: '',
         toggleLabel: '',
         questionSections: {
            section1: {
               title: '',
               rows: [
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: '',
                  },
               ],
            },
            section2: {
               title: '',
               rows: [
                  {
                     title: '',
                     content: {
                        header: '',
                        list: [],
                        footer: '',
                     },
                  },
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: {
                        list: []
                     },
                  },
                  {
                     title: '',
                     content: ''
                  },
                  {
                     title: '',
                     content: ''
                  }
               ],
            },
            section3: {
               title: '',
               rows: [
                  {
                     title: '',
                     content: {
                        part1: '',
                        part2: '',
                     },
                  },
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: [],
                  },
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: '',
                  },
                  {
                     title: '',
                     content: '',
                  },
               ],
            },
            section4: {
               title: '',
               rows: [
                  {
                     title: '',
                     content: [],
                  },
                  {
                     title: '',
                     content: '',
                  },
               ],
            },
         },
         backBtnLabel: '',
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      legal: {
         legalContent: {
            heading: '',
            title: '',
            description: '',
         },
         scopeContent: {
            title: '',
            description: '',
         },
         medicalAdvice: {
            title: '',
            description: '',
         },
         productInfo: {
            title: '',
            description: '',
         },
         copyrightProtect: {
            title: '',
            description: '',
         },
         trade: {
            title: '',
            description: '',
         },
         warranty: {
            title: '',
            description: '',
         },
         transmission: {
            title: '',
            description: '',
         },
         userSubmission: {
            title: '',
            description: '',
         },
         lnktoSites: {
            title: '',
            description: '',
         },
         govLaw: {
            title: '',
            description: '',
         },
         agreement: {
            title: '',
            description: '',
         },
         backBtnLabel: '',
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      privacy: {
         privacyContent: {
            heading: '',
            content: '',
            title: '',
            description: '',
         },
         infobyUsers: {
            title: '',
            description: '',
         },
         personalInfo: {
            title: '',
            description: '',
         },
         nonpersonalInfo: {
            title: '',
            description: '',
         },
         infowithOthers: {
            title: '',
            description: '',
         },
         updateInfo: {
            title: '',
            description: '',
         },
         security: {
            title: '',
            description: '',
         },
         cookies: {
            title: '',
            description: '',
         },
         advertising: {
            title: '',
            description: '',
         },
         beacons: {
            title: '',
            description: '',
         },
         dataCollection: {
            title: '',
            description: '',
         },
         signals: {
            title: '',
            description: '',
         },
         transfer: {
            title: '',
            description: '',
         },
         requirements: {
            title: '',
            description: '',
         },
         enforcement: {
            title: '',
            description: '',
         },
         policies: {
            title: '',
            description: '',
         },
         privacytstmt: {
            title: '',
            description: '',
         },
         privacyforChild: {
            title: '',
            description: '',
         },
         policySecuritynum: {
            title: '',
            description: '',
         },
         privacyMail: {
            heading: '',
            body: [],
            title: '',
         },
         backBtnLabel: '',
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
      renew: {
         banner: {
            title: '',
            image: '',
         },
         renewContent: '',
         assistanceList: {
            title: '',
            list: [],
         },
         table1: {
            headers: [],
            rows: [[[], '']],
         },
         table2: {
            title: '',
            headers: [''],
            rows: [['', '']],
         },
         impInfoList: {
            title: '',
            list: [],
         },
         renewQuestion: {
            question: '',
            answerList: {
               header: '',
               list: [],
               footer: '',
            },
         },
         backBtnLabel: '',
         moreInfo: {
            contactContent: [],
         },
         readMore: {
            text: '',
            btn: {
               label: '',
               link: '',
            },
         },
      },
   });

   useEffect(() => {
      // console.log('local language', localStorage.lang);
      lang === 'spanish' ? setContent(spanish) : setContent(english);
   }, [lang]);

   return (
      <div>
         <ScrollToTop />
         <Header headerData={content.header} lang={lang} setLang={setLang} />
         <Switch>
            <Route
               exact
               path="/home"
               component={() => (
                  <HomePage
                     dialogNotShown={false}
                     pageData={content.home}
                     lang={lang}
                  />
               )}
            />
            <Route
               exact
               path="/about"
               component={() => (
                  <AboutUs pageData={content.about} lang={lang} />
               )}
            />
            <Route
               exact
               path="/eligibility"
               component={() => (
                  <EligibilityRequirements
                     pageData={content.eligibility}
                     lang={lang}
                  />
               )}
            />
            <Route
               exact
               path="/medicines"
               component={() => (
                  <Medicines pageData={content.medicines} lang={lang} />
               )}
            />
            <Route
               exact
               path="/apply"
               component={() => (
                  <HowToApply pageData={content.apply} lang={lang} />
               )}
            />
            <Route
               exact
               path="/faq"
               component={() => <FAQ pageData={content.faq} lang={lang} />}
            />
            <Route
               exact
               path="/renew"
               component={() => (
                  <RenewYourAssistance pageData={content.renew} lang={lang} />
               )}
            />
            <Route
               exact
               path="/legal"
               component={() => (
                  <LegalNotice pageData={content.legal} lang={lang} />
               )}
            />
            <Route
               exact
               path="/privacy"
               component={() => (
                  <PrivacyPolicy pageData={content.privacy} lang={lang} />
               )}
            />
            <Route
               exact
               path="/eligibility-assessment"
               component={() => (
                  <EligibilityAssessment
                     pageData={content.elibility_asmt}
                     lang={lang}
                  />
               )}
            />
            <Route
               exact
               path="/eligibility-assessment/questions"
               component={() => (
                  <QuestionOne
                     pageData={content.eligibility_ques}
                     lang={lang}
                  />
               )}
            />
            <Route
               exact
               path="/eligibility-assessment/not-eligible"
               component={() => (
                  <NotEligible
                     pageData={content.eligibility_fail}
                     lang={lang}
                  />
               )}
            />
            <Route
               exact
               path="/eligibility-assessment/eligible"
               component={() => (
                  <Eligible
                     pageData={content.eligibility_success}
                     lang={lang}
                  />
               )}
            />
            <Route
               exact
               path="/eligibility-assessment/medicare"
               component={() => (
                  <Medicare
                     pageData={content.eligibility_medicare}
                     lang={lang}
                  />
               )}
            />
            <Route
               path="/"
               component={() => (
                  <HomePage
                     dialogNotShown={true}
                     pageData={content.home}
                     lang={lang}
                  />
               )}
            />
         </Switch>
         <Footer footerData={content.footer} />
      </div>
   );
}

export default App;
