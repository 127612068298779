import React  from "react";
import "./style.css";

function IncrementDecrement({startCount, onChange}) {
    function incrementCounter() {
        let newCount = startCount+1 > 9 ? 9 : startCount+1;
        onChange(newCount);
    }

    function decrementCounter() {
        let newCount = startCount - 1 < 1 ? 1 : startCount-1;
        onChange(newCount);
    }

    return (
        <div className="increment-decrement col-lg-3 col-sm-12 d-flex flex-row align-items-center justify-content-center">
            <button className="mx-2 decrement-counter" onClick={() => decrementCounter()}>-</button>
            <span className="mx-2 align-middle counter body-bold">{startCount}</span>
            <button className="mx-2 increment-btn" onClick={() => incrementCounter()}>+</button>
        </div>
    );
}

export default IncrementDecrement;