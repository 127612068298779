import React, { useEffect, useState } from "react";
import bmsLogo from "../../Images/logo.svg";
import Burger from "./Burger";
import Menu from "./Menu";
import { useHistory, useLocation } from "react-router-dom";
import Notification from "./Notification";
import "primeicons/primeicons.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import "./style.css";

function Header({ headerData, lang, setLang }) {
  const location = useLocation();
  const [activeIndex, setActiveIndex] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // console.log('location', location);
    if (['/eligibility-assessment', '/eligibility-assessment/questions', '/eligibility-assessment/not-eligible', '/eligibility-assessment/eligible', '/eligibility-assessment/medicare', '/eligibility-assessment/medicare'].indexOf(location.pathname) > -1) {
      const currentIndex = headerData.routes.indexOf('/eligibility')
      setActiveIndex(currentIndex)
    } else {
      const currentIndex = headerData.routes.indexOf(location.pathname)
      setActiveIndex(currentIndex);
    }
  }, [location]);

  let history = useHistory();

  const goToHome = (e) => {
    e.preventDefault();
    setActiveIndex(0);
    history.push({ pathname: "/home", state: { dialogNotShown: false } });
  };

  return (
    <div className="header-content container-fluid">
      {/* <Notification isEnable={true} content={headerData.notification} /> */}
      {/* <Notification isEnable={true} content={headerData.cobenfy_launch_banner} /> */}
      <Notification isEnable={true} content={headerData.reapply_for_assistance_message} />
      <div className="logo d-flex align-items-center px-4">
        <a href="/home" onClick={(e) => goToHome(e)}>
          <img className="bms-logo" src={bmsLogo} alt="Bristol Myers Squibb Patient Assistance Foundation" />
        </a>
        <div className='language-toggle'>
          {lang === 'english' ?
            <div lang="es" role="link" tabindex={0} style={{ cursor: 'pointer', color: "black", borderBottom: "2px solid #EB7100", textDecoration: "none", whiteSpace: "nowrap" }} onClick={() => { setLang('spanish'); localStorage.setItem('lang', 'spanish') }}>{'En Español >'}</div>
            :
            <div lang="en" role="link" tabindex={0} style={{ cursor: 'pointer', color: "black", borderBottom: "2px solid #EB7100", textDecoration: "none", whiteSpace: "nowrap" }} onClick={() => { setLang('english'); localStorage.setItem('lang', 'english') }}>{'View in English >'}</div>
          }
        </div>
        <Burger menuOpen={open} setMenuOpen={setOpen} />
      </div>
      <div
        className={
          open
            ? "main-menu navbar navbar-expand d-flex px-4"
            : "hide main-menu navbar navbar-expand d-flex px-4"
        }
      >
        <Menu activeIndex={activeIndex} menu={headerData.header_menu} />
        <div className="menu-contact-us-text">
          <p>
            {headerData.contact.label}
            <span
              style={{
                marginLeft: '15px',
                borderBottom: "2px solid #EB7100",
                whiteSpace: "nowrap",
              }}
            >
              <a href={`tel:${headerData.contact.number}`}>{headerData.contact.number}</a>
            </span>
          </p>
        </div>
        <div className='menu-language-toggle'>
          {lang === 'english' ?
            <div lang="es" tabindex={0} style={{ cursor: 'pointer', color: "black", borderBottom: "2px solid #EB7100", textDecoration: "none", whiteSpace: "nowrap" }} onClick={() => { setLang('spanish'); localStorage.setItem('lang', 'spanish') }}>{'En Español >'}</div>
            :
            <div lang="en" tabindex={0} style={{ cursor: 'pointer', color: "black", borderBottom: "2px solid #EB7100", textDecoration: "none", whiteSpace: "nowrap" }} onClick={() => { setLang('english'); localStorage.setItem('lang', 'english') }}>{'View in English >'}</div>
          }
        </div>
      </div>
    </div>
  );
}

export default Header;
