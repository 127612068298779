import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";

function PrivacyPolicy({ pageData }) {
  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };
  return (
    <main id="main" className="privacy-main-div">
      <div className="privacy-content container">
        <div className="row g-0">
          <div className="privacy-content-body col-md-8 col-sm-12 mx-auto">
            <div>
              <h1>{pageData.privacyContent.heading}</h1>
              <p>{pageData.privacyContent.content}</p>
              <h2>{pageData.privacyContent.title}</h2>
              <p>{pageData.privacyContent.description}</p>
            </div>
            <div>
              <h2>{pageData.infobyUsers.title}</h2>
              <p>{pageData.infobyUsers.description}</p>
            </div>
            <div>
              <h2>{pageData.personalInfo.title}</h2>
              <p>{pageData.personalInfo.description}</p>
            </div>
            <div>
              <h2>{pageData.nonpersonalInfo.title}</h2>
              <p>{pageData.nonpersonalInfo.description}</p>
            </div>
            <div>
              <h2>{pageData.infowithOthers.title}</h2>
              <p>{pageData.infowithOthers.description}</p>
            </div>
            <div>
              <h2>{pageData.updateInfo.title}</h2>
              <p>{pageData.updateInfo.description}</p>
            </div>
            <div>
              <h2>{pageData.security.title}</h2>
              <p>{pageData.security.description}</p>
            </div>
            <div>
              <h2>{pageData.cookies.title}</h2>
              <p>{pageData.cookies.description}</p>
            </div>
            <div>
              <h2>{pageData.advertising.title}</h2>
              <p>{pageData.advertising.description}</p>
            </div>
            <div>
              <h2>{pageData.beacons.title}</h2>
              <p>{pageData.beacons.description}</p>
            </div>
            <div>
              <h2>{pageData.dataCollection.title}</h2>
              <p>{pageData.dataCollection.description}</p>
            </div>
            <div>
              <h2>{pageData.signals.title}</h2>
              <p>{pageData.signals.description}</p>
            </div>
            <div>
              <h2>{pageData.transfer.title}</h2>
              <p>{pageData.transfer.description}</p>
            </div>
            <div>
              <h2>{pageData.requirements.title}</h2>
              <p>{pageData.requirements.description}</p>
            </div>
            <div>
              <h2>{pageData.enforcement.title}</h2>
              <p>{pageData.enforcement.description}</p>
            </div>
            <div>
              <h2>{pageData.policies.title}</h2>
              <p>{pageData.policies.description}</p>
            </div>
            <div>
              <h2>{pageData.privacytstmt.title}</h2>
              <p>{pageData.privacytstmt.description}</p>
            </div>
            <div>
              <h2>{pageData.privacyforChild.title}</h2>
              <p>{pageData.privacyforChild.description}</p>
            </div>
            <div>
              <h2>{pageData.policySecuritynum.title}</h2>
              <p>{pageData.policySecuritynum.description}</p>
            </div>
            <div className="privacy-mail-address">
              <h2>{pageData.privacyMail.heading}</h2>
              <p>
                {pageData.privacyMail.body.map((item, index) => {
                  return <> {item}<br /></>;
                })}
                <a href="mailto:bms@cambridge-partners.com">
                  <span class="bold-span">{pageData.privacyMail.title}</span>
                </a>
              </p>
              {/* <p>
                If you have any questions about the Web site's Internet Privacy
                Statement or other aspects of privacy on our Web site, please
                contact us at (800)-332-2056.
                <br />
                Bristol-Myers Squibb Patient Assistance Foundation
                <br />
                P.O. Box 640
                <br />
                Palatine, IL 60078-0640
                <br />
                800-332-2056
                <br />
                <a href="mailto:bms@cambridge-partners.com">
                  <span class="bold-span">E-mail</span>
                </a>
              </p> */}
            </div>
          </div>
        </div>
        <div className="privacy-back-to-top row g-0" style={{ marginBottom: "2rem" }}>
          <div className="col-md-8 col-sm-12 mx-auto" >
            <Link
              tabIndex={0}
              style={{
                marginTop: "1em",
                color: "black",
                "border-bottom": "2px solid #EB7100",
                textDecoration: "none",
              }}
              onClick={(e) => backToTop(e)}
            >
              <span class="bold-span">{pageData.backBtnLabel}</span>{" "}
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default PrivacyPolicy;
