import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import correctSign from "../../Images/correct-sign.svg";
import review from "../../Images/review.svg";
import ifApproved from "../../Images/Eligibility.svg";
import reapply from "../../Images/reapply.svg";
import { Button } from "primereact/button";
import ReadMore from "../ReadMore/ReadMore";
import { useLocation } from "react-router-dom";
import WhatsNext from "./WhatsNext";
import ContactUs from "./ContactUs";
import DownloadForm from "../HowToApply/DownloadForm";

function Medicare({ pageData, lang }) {
  const location = useLocation();
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const [annualIncome, setAnnualIncome] = useState(0);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      setSelectedMedicine(location.state.prescribedMedicine);
      setAnnualIncome(location.state.annualIncome);
    }
  });

  const backToTop = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
  };

  const openDocument = () => {
    setShowDialog(true);
  };

  const updateShowDialog = (isShow) => {
    setShowDialog(isShow);
  };

  return (
    <main id="main">
      <div className="medicare-eligible-main-div">
        <div className="medicare-eligible-header container">
          <div className="row g-0">
            <div className="col-sm-12 mx-auto">
              <h1>{pageData.title}</h1>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row g-0">
            <div className="medicare-eligible col-xl-12 col-lg-12 col-md-12 col-sm-12 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
              <span className="medicare-correct-icon">
                <img
                  className="correct-icon"
                  src={correctSign}
                  alt="correct-sign"
                />
              </span>
              <span className="medicare-eligible-text">
                <h3 dangerouslySetInnerHTML={{ __html: pageData.dialogue }}></h3>
              </span>
            </div>
          </div>
        </div>
        <div className="additional-requirements container">
          <div className="row g-0">
            <div className="col-md-8 col-sm-12 mx-auto">
              <p className="body-large">{pageData.body1.title}</p>
              <div className="custom-bullet-main-div">
                <ul className="custom-list">
                  <li dangerouslySetInnerHTML={{ __html: pageData.body1.bullet1 }}></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row g-0">
            <div className="prescription-expenses col-md-8 col-sm-12 mx-auto">
              <div>
                <h4>{pageData.body1.header}</h4>
              </div>
              <div className="medicare-eligible-content">
                <ul className="custom-list">
                  {pageData.body1.list1.map(item => (
                    <li dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                  <li>{pageData.body1.prescriptionExpenses.part1}<span className='highlight-income'>{" "}${Math.round(0.03 * annualIncome)}{" "}</span>{pageData.body1.prescriptionExpenses.part2}</li>
                  {pageData.body1.list2.map(item => (
                    <li dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <hr aria-hidden="true" />
          <div className="row g-0">
            <div className="application-details col-md-8 col-sm-12 mx-auto">
              <div><h4><span className="circle-outline">{pageData.body2.index_1.id}</span>&nbsp;{pageData.body2.index_1.title}</h4></div>
              <div className="custom-list-main-div">
                <ul className="custom-list">
                  {pageData.body2.index_1.list.map(item => (
                    <li dangerouslySetInnerHTML={{ __html: item }}></li>
                  ))}
                </ul>
              </div>
              <div className="button-contact">
                <span>
                  {" "}
                  <Button
                    label={pageData.body2.index_1.btnLabel}
                    className="p-button-outlined p-button-help"
                    onClick={() => openDocument()}
                  />{" "}
                </span>
                <span className="contact-fax"><p dangerouslySetInnerHTML={{ __html: pageData.body2.index_1.contactMsg }}></p></span>
              </div>
              <hr aria-hidden="true" />
            </div>
          </div>
          <div className="row g-0">
            <div className="medicare-ask-doctor col-md-8 col-sm-12 mx-auto">
              <p><h4><span className="circle-outline">{pageData.body2.index_2.id}</span>&nbsp;{pageData.body2.index_2.title}</h4></p>
              <p className="application-status">{pageData.body2.index_2.text}</p>
              <hr aria-hidden="true" />
            </div>
          </div>
          <div className="row g-0">
            <div className="medicare-custom-list col-md-8 col-sm-12 mx-auto">
              <p><h4><span className="circle-outline">{pageData.body2.index_3.id}</span>&nbsp;{pageData.body2.index_3.title}</h4></p>
              <ul className="custom-list">
                {pageData.body2.index_3.list.map(item => (
                  <li dangerouslySetInnerHTML={{ __html: item }}></li>
                ))}
              </ul>
            </div>
          </div>
          <div className="row g-0">
            <ContactUs contactData={pageData.moreInfo.contactContent} />
          </div>
          <hr aria-hidden="true" />
        </div>
        <WhatsNext content={pageData.whatsNext} />
        <div className="eligible-back-to-top row g-0">
          <div className="col-md-8 col-sm-12 mx-auto">
            <Link
              tabIndex={0}
              style={{
                marginTop: "1em",
                color: "black",
                borderBottom: "2px solid #EB7100",
                textDecoration: "none",
              }}
              onClick={(e) => backToTop(e)}
            >
              <span class="bold-span">{pageData.backBtnLabel}</span>{" "}
            </Link>
          </div>
        </div>
        <ReadMore content={pageData.readMore} />
      </div>
      <DownloadForm
        lang={lang}
        dialogData={pageData.downloadForm}
        selectedMedicine={{ medicineDetails: selectedMedicine }}
        showDialog={showDialog}
        updateShowDialog={updateShowDialog}
      />
    </main>
  );
}

export default Medicare;
