import React, { useState } from "react";
import "./style.css";
import { Link } from "react-router-dom";

function ReadMore({ content }) {
  return (
    <div className="container-fluid">
      <div className="read-more-row row g-0">
        <div className="read-more col-md-10 col-sm-12 mx-auto">
          <p className="readmore-content align-middle">
            <span className="read-more-text">{content.text}&nbsp;</span>
            <span className="read-more-link"><Link to={content.btn.link}>{content.btn.label}</Link></span></p>
        </div>
      </div>

    </div>
  );
}

export default ReadMore;